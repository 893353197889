import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Hero from "../components/Hero/Hero";
import HeroMini from "../components/HeroMini/HeroMini";
import Layout from "../components/Layout";
import IconFeatureContainer from "../components/IconFeatures/IconFeature";
import Card from "../components/Card/Card";
import ContentSection from "../components/ContentSection/ContentSection";
import { StyledCardContainer } from "../components/Card/Card.styled";
import Button from "../components/Buttons/Button";
import { MainHeading } from "../components/Titles/MainHeading";

export const IndexPageTemplate = ({
  image,
  heading,
  thirdhero,
  secondhero,
  iconfeatures,
  joinup,
  blogPattern,
  overlayicon,
  articletitle: articleTitle,
}) => {
  return (
    <div>
      <Hero
        heading={heading}
        backgroundImage={image}
        overlayicon={overlayicon}
      />
      <IconFeatureContainer icons={iconfeatures} contained />
      <HeroMini
        render={
          <Link to={secondhero.secondherolink || "#"}>
            <Button text={secondhero.linkheading || ""}>
              {secondhero.secondherolink}
            </Button>
          </Link>
        }
        backgroundImage={secondhero.secondheroimage}
        maxWidth={"1000px"}
      />
      <HeroMini
        extraStyles={{
          flexDirection: `column`,
          textAlign: `center`,
          backgroundSize: "110%",
        }}
        render={
          <>
            <div className="heading">{thirdhero.thirdheroheading || ""}</div>
            <div className="subheading">
              {thirdhero.thirdherosubheading || ""}
            </div>
            <Link to={thirdhero.thirdherolink || "#"}>
              <Button text={thirdhero.thirdherobuttonverbiage || ""} />
            </Link>
          </>
        }
        backgroundImage={thirdhero.thirdheroimage || ""}
        maxWidth={false}
        useGradient
      />
      <MainHeading>{articleTitle}</MainHeading>
      <div
        style={{
          overflow: "hidden",
        }}
      >
        <StyledCardContainer>
          {blogPattern.edges.map(
            ({
              node: {
                frontmatter: { description, title, featuredimage },
                fields: { slug },
              },
            }) => {
              return (
                <Card
                  backgroundImage={featuredimage}
                  heading={title}
                  subheading={description}
                  linkContent={{
                    link: `${slug}`,
                    button: "EXPLORE",
                  }}
                />
              );
            }
          )}
        </StyledCardContainer>
      </div>
      <ContentSection
        {...{
          heading: joinup.heading,
          subheading: joinup.subheading,
          variant: "red",
          hideSubheadingOnMediaBreak: true,
        }}
      >
        <Link to={joinup.button.link || "/"}>
          <Button text={joinup.button.title} />
        </Link>
      </ContentSection>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  thirdhero: PropTypes.object,
  iconfeatures: PropTypes.array,
  blogPattern: PropTypes.object,
  secondhero: PropTypes.object,
  joinup: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
    blogPattern,
  } = data;
  return (
    <Layout>
      <IndexPageTemplate {...{ ...frontmatter, blogPattern }} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

// export const LastBlogQueries = graphql`
// `;

export const pageQuery = graphql`
  query IndexPageTemplate {
    blogPattern: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        thirdhero {
          thirdheroimage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          thirdherolink
          thirdheroheading
          thirdherosubheading
          thirdherobuttonverbiage
        }
        secondhero {
          secondheroimage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          secondherolink
          linkheading
        }
        articletitle
        overlayicon {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        heading
        iconfeatures {
          image {
            childImageSharp {
              fluid(maxWidth: 240, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          heading
        }
        joinup {
          heading
          subheading
          button {
            link
            title
          }
        }
      }
    }
  }
`;
