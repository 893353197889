import React from "react";
import { HeroMiniStyled } from "./HeroMini.styled";
import PropTypes from "prop-types";

const HeroMini = ({
  render,
  fullWidth = false,
  backgroundImage = "",
  extraStyles = {},
  useGradient = false,
  ...rest
}) => {
  return (
    <HeroMiniStyled
      style={{
        ...extraStyles,
      }}
      imageInfo={backgroundImage || "" }
      {...rest}
    >
      {useGradient && <section className="gradientBrown"></section>}
      {render}
    </HeroMiniStyled>
  );
};

HeroMini.propTypes = {
  backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired, // URL
  render: PropTypes.node.isRequired,
  fullWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default HeroMini;
