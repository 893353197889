import styled from "styled-components";

export const IconDenoter = styled.div`
  width: 100px;
  height: 100px;
  opacity: 0.8;
  position: absolute;
  bottom: 25%;
  right: 10%;
`;

export const HeroStyled = styled.div`
section {
  position: relative;
  width: 100vw;
  height: 500px;
  min-height: 240px;
  background-repeat: no-repeat;
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
      height: auto;
      background-position:center right;
      background-size:100vw;
  }
  display:flex;
  align-items: center;
  background-size: fit;
  background-position: 66% 70%;
  h1 {
    ${({ type, theme }) => {
      switch (type.toString().toUpperCase()) {
        case "GIANT":
          return theme.common.typography.heroGiant;
        default:
          return theme.common.typography.heroLarge;
      }
    }};
    padding-left: 66px;
    width: 48.82%;
    ${({ location, theme }) => {
      if (location.toString().toUpperCase() === "CENTER") {
        return `
          width: 58.82%;
          text-align:center;
          color: ${theme.common.palette.white};
        `;
      }
    }}
    @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
      font-weight: 600;
      font-size: 34px;
      line-height: 40px;
      padding-left: 40px;
    }
  }
  
  ${({ location, theme }) => {
    if (location.toString().toUpperCase() === "CENTER") {
      return "justify-content: center";
    } else {
      return "justify-content: left";
    }
  }}
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    min-height: 240px;
    height: auto;
  }
}
`;
