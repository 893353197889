import React from "react";
import { HeroStyled, IconDenoter } from "./Hero.styled";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../PreviewCompatibleImage";

const Hero = ({
  heading,
  backgroundImage = "",
  overlayicon,
  type,
  location,
  children,
}) => {
  return (
    <HeroStyled loading="lazy" type={type} location={location}>
      <PreviewCompatibleImage
        cover
        style={{ width: "100%" }}
        imageInfo={backgroundImage || "" }
        Tag="section"
        cover
      >
        <h1>{heading}</h1>
        {!!overlayicon ? (
          <IconDenoter>
            <PreviewCompatibleImage cover imageInfo={overlayicon || "" }/>
          </IconDenoter>
        ) : null}
        {children}
      </PreviewCompatibleImage>
    </HeroStyled>
  );
};

Hero.propTypes = {
  heading: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired, // URL
  location: PropTypes.string,
  type: PropTypes.string,
  overlayicon: PropTypes.object,
};

Hero.defaultProps = {
  Icon: false,
  location: "CENTER",
  type: "GIANT",
};

export default Hero;
