import styled from "styled-components";

export const StyledCardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto 30px auto;
  width: 90%;
  overflow: auto;
  div {
    flex: 1;
    min-width: 300px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    width: 100%;
    justify-content: left;
  }
`;

export const StyledCard = styled.div`
  min-width: 250px;
  height: auto;
  margin: 5px;
  display: flex;
  flex-direction: column;
`;

export const CardImage = styled.div`
  * {
    width: 100%;
    height: 230px;
    background-position: center center;
    background-size: 110%;
  }
  flex: 1;
`;

export const CardHeading = styled.h2`
  ${({ theme }) => theme.common.typography.h2LineHeight};
  margin: 10px 0px 0px 0px;
  padding-bottom: 0px;
  flex: 1;
`;

export const CardSubheading = styled.p`
  ${({ theme }) => theme.common.typography.bodyNormal};
  font-size: 20px;
  color: ${({ theme }) => theme.common.palette.darkGray};
  flex: 2;
`;
