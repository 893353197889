import React from "react";
import Button from "../Buttons/Button";
import { Link } from "gatsby";
import {
  CardHeading,
  CardSubheading,
  CardImage,
  StyledCard,
} from "./Card.styled";
import PreviewCompatibleImage from "../PreviewCompatibleImage";

const Card = ({ backgroundImage, heading, subheading, linkContent }) => {
  return (
    <StyledCard>
      <CardImage>
        <PreviewCompatibleImage cover imageInfo={backgroundImage || "" } Tag="section" />
      </CardImage>
      <CardHeading>{heading}</CardHeading>
      <CardSubheading>{subheading}</CardSubheading>
      {linkContent.button && (
        <Link to={linkContent.link || "#"}>
          <Button text={linkContent.button} />
        </Link>
      )}
    </StyledCard>
  );
};

Card.defaultProps = {
  linkContent: {
    link: false,
    button: false,
  },
};

export default Card;
