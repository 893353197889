import styled from "styled-components";

export const IconFeatureItem = styled.div`
  width: 180px;
  height: 166px;
  justify-content: left;
  .icon_img {
    margin-bottom: 24px;
    height: 96px;
    background-position: center center;
    flex: 1;
  }
  span {
    flex: 3;
  }
  ${({ theme }) => theme.common.typography.iconHeading};
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    margin: 5px auto;
    width: 90%;
    max-width:400px;
    .icon_img {
      height: 50px;
      width: 50px;
      background-size:contain;
      margin-right:15px;
    }
    height: auto;
    display: flex;
    justify-content:left;
    text-align: left;
  }
`;

export const IconFeatureContainer = styled.div`
  width: 62.85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 82px auto;
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    display:block;
    height: auto;
    align-items:flex-start;
    justify-content: left;
  }
`;
