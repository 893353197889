import React from "react";
import PropTypes from "prop-types";
import {
  StyledContentSection,
  StyledSectionTitle,
  StyledSectionSubheading,
} from "./ContentSection.styled";

const ContentSection = ({
  heading,
  subheading,
  variant = "red",
  children,
  hideSubheadingOnMediaBreak = false,
}) => {
  return (
    <StyledContentSection
      variant={variant}
      className={variant.toString().toLowerCase()}
    >
      <StyledSectionTitle variant={variant}>{heading}</StyledSectionTitle>
      <StyledSectionSubheading
        variant={variant}
        hideSubheadingOnMediaBreak={hideSubheadingOnMediaBreak}
      >
        {subheading}
      </StyledSectionSubheading>
      {children}
    </StyledContentSection>
  );
};

ContentSection.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  variant: PropTypes.oneOf(["red", "white", "darkGray", "dusty"]),
};

export default ContentSection;
