import styled from "styled-components";

export const StyledContentSection = styled.div`
  width: 100vw;
  height: auto;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;

  button {
    background: ${({ theme, variant }) =>
      variant !== "white"
        ? theme.common.palette.darkGray
        : theme.common.palette.red};
  }

  &.red {
    background: ${({ theme }) => theme.common.palette.red};
  }

  &.white {
    background: ${({ theme }) => theme.common.palette.white};
  }

  &.darkGray {
    background: ${({ theme }) => theme.common.palette.darkGray};
  }

  &.dusty {
    background: ${({ theme }) => theme.common.palette.brownGray};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    padding: 50px 15%;
    flex-direction:column;
    justify-content:flex-start;
    align-items: flex-start;
    min-height: unset;
  }
`;

export const StyledSectionTitle = styled.h2`
  ${({ theme }) => theme.common.typography.heroLarge};
  line-height: 40px;
  width: 60%;
  min-width: 300px;
  text-align: center;
  color: ${({ theme, variant }) =>
    variant !== "white"
      ? theme.common.palette.white
      : theme.common.palette.darkGray};
  text-transform: uppercase;
  margin: 0px;
  padding: 0px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    text-align: left;
    margin: 10px 0px;
  }
`;

export const StyledSectionSubheading = styled.p`
  ${({ theme }) => theme.common.typography.bodyNormal};
  width: 60%;
  min-width: 300px;
  text-align: center;
  color: ${({ theme, variant }) =>
    variant !== "white"
      ? theme.common.palette.white
      : theme.common.palette.darkGray};
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    ${({ hideSubheadingOnMediaBreak }) =>
      hideSubheadingOnMediaBreak ? `display: none` : ""};
  }
`;
