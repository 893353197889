import styled from "styled-components";

export const MainHeading = styled.h1`
  ${({ theme }) => theme.common.typography.h1};
  line-height: 34px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom:42px;
  color: ${({theme}) => theme.common.palette.brownGray};
`;
