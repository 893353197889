import styled from "styled-components";
import PreviewCompatibleImage from '../PreviewCompatibleImage';

export const HeroMiniStyled = styled(PreviewCompatibleImage)`
  div, button, a {
    z-index: 2;
  }
  section {
    z-index: 1;
  }
  position:relative;
  height: 240px;
  ${({ maxWidth }) => {
    if (maxWidth) {
      return `max-width: ${maxWidth}`;
    } else {
      return "";
    }
  }};
  z-index: 0;
  .gradientBrown {
    width: 100%;
    height: 100%;
    position:absolute;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    background: ${({ theme }) =>
      `linear-gradient(${theme.common.palette.brownGradientStart}, ${theme.common.palette.brownGradientEnd})`};
  }
  .heading, .subheading {
    width: 65%;
  }
  .heading {
   ${({ theme }) => theme.common.typography.heroMedium};
   text-transform: uppercase;
  }
  .subheading{
    ${({ theme }) => theme.common.typography.bodyNormal}
    color: ${({ theme }) => theme.common.palette.white};
    margin: 19px auto;
  }
  width: 100vw;
  height: 500px;
  display: flex;
  margin: 60px auto;
  align-items: center;
  justify-content: center;
  background-position: center center;
  background-size: cover;
  @media (max-width: ${({ theme }) => theme.breakpoints.mediaSmall}) {
    align-items: center;
    min-height: 375px;
    height: auto;
    padding:20px 0px;
  }
`;
