import React from "react";
import { IconFeatureItem, IconFeatureContainer } from "./IconFeature.styled";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../PreviewCompatibleImage";

const RenderIcons = ({ icons }) =>
  icons.map(({ image, heading }) => {
    return (
      <IconFeatureItem>
        <div
          className="icon_img"
          style={{
            backgroundRepeat: `no-repeat`,
          }}
        >
          <PreviewCompatibleImage imageInfo={image || ""} />
        </div>
        <span
          style={{
            wordSpacing: "100vw",
          }}
        >
          {heading}
        </span>
      </IconFeatureItem>
    );
  });

const IconFeature = ({ icons, contained = true }) => {
  if (contained) {
    return (
      <IconFeatureContainer>
        <RenderIcons icons={icons} />
      </IconFeatureContainer>
    );
  } else {
    return <RenderIcons icons={icons} />;
  }
};

IconFeature.propTypes = {
  icons: PropTypes.arrayOf([
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      heading: PropTypes.string,
    }),
  ]).isRequired,
  contained: PropTypes.bool,
};

export default IconFeature;
